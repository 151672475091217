<template>
   <header style="margin-top: -150px" class="masthead">
        <div class="container px-5">
        <div class="row gx-5 align-items-center">
          <div class="col-lg-6">
            <!-- Mashead text and app badges-->
            <div class="mb-5 mb-lg-0 text-center text-lg-start">
              <h1 class="displays-1 lh-1 mb-3">{{ $t("home2.a1") }}</h1>
              <p class="lead fw-normal text-muteds mb-5">
                {{ $t("home2.a2") }}
              </p>

              <!-- <v-row dense justify="center">
                <v-col cols="6">

                </v-col>
                <v-col cols="6">

                </v-col>
                <div class="col">
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.smartcode.rentcar"
                    ><img
                      class="app-badge"
                      src=".././assets/img/google-play-badge.svg"
                      alt="..."
                  /></a>
                  <a
                    target="_blank"
                    href="https://apps.apple.com/us/app/nene-rent-a-car/id1645147496"
                    ><img
                      class="app-badge"
                      src=".././assets/img/app-store-badge.svg"
                      alt="..."
                  /></a>
                </div>
              </v-row> -->
              <v-row class="hidden-sm-and-up" dense justify="center">
                <v-col cols="6" xs="6" md="6" align="end">
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.smartcode.rentcar"
                    ><v-img
                      contain
                      class="app-badge"
                      src=".././assets/img/google-play-badge.svg"
                      alt="google play img"
                  /></a>
                </v-col>
                <v-col cols="6" xs="6" md="6" align="start">
                  <a
                    target="_blank"
                    href="https://apps.apple.com/us/app/nene-rent-a-car/id1645147496"
                    ><v-img
                      contain
                      class="app-badge"
                      src=".././assets/img/app-store-badge.svg"
                      alt="app storage image"
                  /></a>
                </v-col>
              </v-row>
              <v-row class="hidden-sm-and-down">
                <div class="d-flex flex-row flex-lg-row align-items-center">
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=dev.neurosoft.goldrentcar"
                    ><img
                      class="app-badge"
                      src=".././assets/img/google-play-badge.svg"
                      alt="..."
                  /></a>
                  <a
                    target="_blank"
                    href="https://apps.apple.com/us/app/gold-rent-car/id6450556275"
                    ><img
                      class="app-badge"
                      src=".././assets/img/app-store-badge.svg"
                      alt="..."
                  /></a>
                </div>
              </v-row>
            </div>
          </div>
          <div class="col-lg-6">
            <!-- Masthead device mockup feature-->
            <div class="masthead-device-mockup">
              <svg
                class="circle"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <linearGradient
                    id="circleGradient"
                    gradientTransform="rotate(45)"
                  >
                    <stop class="gradient-start-color" offset="0%"></stop>
                    <!-- <stop class="gradient-end-color" offset="100%"></stop> -->
                  </linearGradient>
                </defs>
                <circle cx="50" cy="50" r="50"></circle>
              </svg>

              <svg
                class="shape-1 d-none d-sm-block"
                viewBox="0 0 240.83 240.83"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="-32.54"
                  y="78.39"
                  width="305.92"
                  height="84.05"
                  rx="42.03"
                  transform="translate(120.42 -49.88) rotate(45)"
                ></rect>
                <rect
                  x="-32.54"
                  y="78.39"
                  width="305.92"
                  height="84.05"
                  rx="42.03"
                  transform="translate(-49.88 120.42) rotate(-45)"
                ></rect>
              </svg>

              <svg
                class="shape-2 d-none d-sm-block"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="50" cy="50" r="50"></circle>
              </svg>
              <div class="device-wrapper">
                <div
                  class="device"
                  data-device="iPhoneX"
                  data-orientation="portrait"
                  data-color="black"
                >
                  <div class="screen bg-black">
                    <!-- PUT CONTENTS HERE:-->
                    <!-- * * This can be a video, image, or just about anything else.-->
                    <!-- * * Set the max width of your media to 100% and the height to-->
                    <!-- * * 100% like the demo example below.-->
                    <img
                      src=".././assets/img/preview_01.png"
                      style="max-width: 100%; height: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
</template>
<script>
import { GET_NOTIICATIONS, COUNT_NOTIFICATIONS } from "../gql/queries";
import { READ_NOTIFICAION } from "../gql/mutation";
import moment from "moment";
export default {
    name: "download-app",
    data() {
        return {
            notifications: [],
        };
    },
    created() {
        //this.getData();
    },
    methods: {
        async getNotifications() {
            const cNotifications = await this.$apollo.query({
                query: COUNT_NOTIFICATIONS,
                variables: { userId: this.$store.state.Auth.user.rowId },
            });
        },
        async readNotification(item) {
            try {
                var data = await this.$apollo.mutate({
                    mutation: READ_NOTIFICAION,
                    variables: {
                        notificationId: item.rowId,
                    },
                    update: await this.getNotifications(),
                });
            } catch (err) {
                this.$toast.error("A ocurrido un error " + err);
            }
        },
        getYears(year) {
            moment.locale("es");
            return moment(year).fromNow();
        },
        async getData() {
            var notification = await this.$apollo.query({
                query: GET_NOTIICATIONS,
                variables: { userId: this.$store.state.Auth.user.rowId },
            });

            this.notifications = notification.data.notifications.nodes;
        },
    },
    computed: {
        profile() {
            return this.$store.state.Auth;
        },
    },
};
</script>
 <style scoped src="../assets/style.css"></style>