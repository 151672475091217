<template>
  <v-col data-aos="fade-up" data-aos-duration="800" cols="6">
    <v-card
      :to="`/vehicles/${item.rowId}`"
      color="white"
      class="pt-0 rounded-lg"
      height="250"
      :elevation="elevation"
    >
      <v-img
        style="margin-top: 100"
        :aspect-ratio="16 / 10"
        :src="item.vehicleImages.nodes[0].imageUrl"
      >
      </v-img>
      <!-- <v-carousel
        :style="
          item.isDeluxe
            ? ' ;border: 8px solid  rgb(212, 175, 55) ; 	border-radius: 0.15rem; '
            : 'color:black'
        "
        height="auto"
        hide-delimiter-background
        hide-delimiters
        class="rounded-xl"
      >
        <template v-if="maintenance == false">
          <v-carousel-item
            :to="`/vehicles/${item.vehicleImages.nodes[0].rowId}`"
            aspect-ratio="1.0"
            :src="item.vehicleImages.nodes[0].imageUrl"
          >
            <template v-slot:placeholder>
              <v-sheet>
                <v-skeleton-loader
                  class="mx-auto"
                  width="auto"
                  height="auto"
                  type="image,image"
                ></v-skeleton-loader>
              </v-sheet>
            </template>
            <div
              v-if="item.isDeluxe && maintenance == false"
              style=""
              class="pa-4 text-right"
            >
              <v-col cols="12" md="12">
                <v-row justify="end">
                  <div
                    class="pa-2 text-center rounded-xl"
                    style="background-color: #d4af37"
                  >
                    <span
                      style="font-size: 14px; border-radius: 0.6rem"
                      class="font-weight-bold text-center"
                    >
                      DELUXE</span
                    >
                  </div>
                </v-row>
              </v-col>
            </div>
          </v-carousel-item>
        </template>
      </v-carousel> -->

      <v-row justify="center" class="px-6 mt-2">
        <span
          style="color: black; font-size: 1em"
          class="font-weight-bold text-center"
          >{{ item.vehicleBrand.name }} {{ item.vehicleModel.name }}
          {{ item.year }}</span
        >
      </v-row>
      <v-row justify="center" class="px-6 pt-0">
        <span
          style="color: gray; font-size: 1em"
          class="font-weight-normal text-center pt-0"
        >
          Pasajeros:
          <v-icon class="mb-1 ml-2" size="15px">person</v-icon>
          {{ item.personQuantity }}
        </span>
      </v-row>

      <v-row justify="center" class="px-6 pt-1 pb-4">
        <span class="font-weight-bold" style="color: black; font-size: 1em">
          ${{ item.basePrice }} / Dia
        </span>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "itemCard",
  data() {
    return {
      isLoad: false,
      isFavorite: false,
    };
  },
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },

    elevation: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    show(item) {
      this.$emit("show-edit", item);
    },
    async loaded() {
      this.isLoad = true;
    },
    next(i) {},
    load(item) {},
  },
  computed: {
    Authentication() {
      return this.$store.state.Auth;
    },
  },
};
</script>
<style scoped>
.img {
  object-fit: fill;
}
skeleton-loader-image-height {
  height: 200px;
}
.inner-img {
  transition: 0.3s;
}
.img-wrapper {
  overflow: hidden;
}

.inner-img:hover {
  transform: scale(1.1);
}
</style>
