<template>
  <v-container class="mt-n1" style="background-color: #fafafa" fluid>
    <v-row>
      <v-carousel height="auto" hide-delimiter-background cycle>
        <v-carousel-item
          v-for="(item, i) in items"
          :src="item.src"
          aspect-ratio="2.67"
          :key="i"
        >
        </v-carousel-item>
      </v-carousel>
    </v-row>
    <v-row style="">
      <v-layout
        style="opacity: 90%; margin-top: -400px"
        class=""
        align-center
        justify-end
        fill-height
      >
        <v-col cols="12" md="3">
          <v-row class="pr-16">
            <SearchCarsVue
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
              :vehicleBrandes="vehiclesBrandes"
              :vehicleModels="vehicleModels"
              :type="2"
            />
          </v-row>
        </v-col>
      </v-layout>
    </v-row>

    <v-container fluid id="features" style="margin-top: 50px">
      <v-row align="center" justify="center">
        <v-col cols="12" md="8">
          <v-row align="center" justify="space-around">
            <v-col
              cols="12"
              md="4"
              class="text-center"
              v-for="(item, i) in articles"
              :key="i"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  width="100%"
                  height="180px"
                  class="rounded-lg"
                  :color="
                    item.icon == `mdi-calendar-month-outline`
                      ? `primary`
                      : `black `
                  "
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-icon
                    size="50px"
                    color="white"
                    class="mt-5"
                    :class="{ 'zoom-efect': hover }"
                  >
                    {{ item.icon }}
                  </v-icon>

                  <h1 class="font-weight-regular white--text">
                    {{ item.title }}
                  </h1>
                  <h4 class="font-weight-regular subtitle-1 white--text">
                    {{ item.text }}
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-row
      data-aos="fade-up"
      data-aos-duration="800"
      class="mt-4"
      style="background-color: black"
      justify="center"
    >
      <v-col class="mt-n3 mb-6" cols="12" md="12">
        <v-card elevation="0">
          <v-row>
            <v-col
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="400"
              cols="12"
              md="6"
            >
              <v-img aspect-ratio="1" src="../assets/141.jpg"> </v-img>
            </v-col>
            <v-col cols="12" md="6">
              <v-container fill-height grid-list-md text-xs-center>
                <v-layout row wrap align-center>
                  <v-flex>
                    <v-row justify="center">
                      <span
                        style="font-size: 2.8vh"
                        class="primary--text my-application2"
                        >{{ this.$t("home.a2.1") }}</span
                      ></v-row
                    >
                    <v-row justify="center">
                      <span
                        style="font-size: 2.5vh"
                        class="black--text my-application2"
                        >{{ this.$t("home.a2.2") }}</span
                      >
                    </v-row>
                    <v-row justify="center">
                      <v-container>
                        <span
                          style="font-size: 2.3vh"
                          class="black--text my-application"
                          >{{ this.$t("home.a2.3") }}</span
                        >
                      </v-container>
                    </v-row>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-col>
          </v-row>

          <!-- ;error -->
          <v-row class="mr-5 ml-5">
            <v-col cols="12" md="4">
              <v-img contain aspect-ratio="1" src="../assets/143.jpeg"> </v-img
            ></v-col>
            <v-col cols="12" md="4">
              <v-img contain aspect-ratio="1" src="../assets/144.jpeg"> </v-img>
            </v-col>
            <v-col cols="12" md="4">
              <v-img contain aspect-ratio="1" src="../assets/145.jpeg"> </v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-container fluid>
        <v-row justify="center">
          <v-col cols="12">
            <v-row justify="center">
              <v-col cols="12" md="12">
                <h1
                  style="font-size: 1.5em"
                  class="my-application hidden-sm-and-down pa-6"
                >
                  {{ this.$t("home.vehiculoPopulares") }}
                  <v-btn
                    @click="go()"
                    outlined
                    color="black"
                    class="float-right"
                    ><v-icon>menu</v-icon> Ver catalogo</v-btn
                  >
                </h1>

                <span
                  style="font-size: 1em"
                  class="my-application hidden-sm-and-up pa-4"
                >
                  {{ this.$t("home.vehiculoPopulares") }}
                  <v-btn
                    @click="go()"
                    outlined
                    color="black"
                    class="float-right"
                    ><v-icon>menu</v-icon>
                  </v-btn>
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center">
          <ItemCard
            class="px-6 hidden-sm-and-down"
            v-for="item in vehicles"
            :key="item.id"
            :item="item"
            :type="2"
          />
          <ItemCard2
            class="hidden-sm-and-up"
            v-for="item in vehicles"
            :key="item.id"
            :item="item"
            :elevation="0"
          />
        </v-row>
        <v-row class="mt-0 mb-1 hidden-sm-and-up" justify="center">
          <v-col cols="4">
            <v-btn @click="go()" outlined color="black"> Ver Mas</v-btn>
          </v-col>
        </v-row>

        <v-row class="mt-2 mb-1 hidden-sm-and-down" justify="center">
          <v-col cols="1">
            <v-btn @click="go()" outlined color="black"> Ver Mas</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <div class="pb-8">
      <v-parallax height="400" src="../assets/fondo/audi.jpg">
        <v-layout style="opacity: 90%" align-center justify-center>
          <span class="my-application3 text-center" style="font-size: 3.5vw">
            Comunicate con nosotros available 24/7
          </span>
          <v-icon class="px-4" size="70px" color="red"> phone </v-icon>
          <span class="my-application3 text-center" style="font-size: 3.5vw">
            {{ phone }}
          </span>
        </v-layout></v-parallax
      >
    </div>

    <v-row justify="center" class="mt-8 pb-8">
      <v-container fluid>
        <v-row justify="center">
          <span class="my-application2 pb-4" style="font-size: 3vw">{{
            this.$t("home.supcripciones")
          }}</span>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="12">
            <v-responsive>
              <Supcriptions v-if="loading" :supcriptions="supcriptions" />
            </v-responsive>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row style="background-color: #1c1c1c; margin-bottom: 50px">
      <v-container>
        <v-row justify="center" style="margin-top: 50px">
          <span
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            class="primary--text my-application2"
            >GOLD RENT CAR
          </span>
        </v-row>

        <v-row justify="center" style="margin-top: 50px">
          <span
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            style="font-size: 3.5vw"
            class="white--text my-application2"
            >{{ this.$t("home.a3.title") }}
          </span>
        </v-row>

        <v-row justify="center" class="mb-15 mt-10">
          <v-col
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
            cols="12"
            md="3"
            ><v-row justify="center">
              <v-icon dense size="50px" color="white">mdi-car </v-icon>
            </v-row>
            <v-row class="mt-8" justify="center">
              <span
                style="font-size: 1.8em"
                class="primary--text my-application2"
                >{{ this.$t("home.a3.1.name") }}
              </span>
            </v-row>
            <v-row class="mt-8" justify="center">
              <p
                style="font-size: 1.5em"
                class="white--text my-application text-center"
              >
                {{ this.$t("home.a3.1.details") }}
              </p>
            </v-row>
          </v-col>

          <v-col
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1000"
            cols="12"
            md="3"
            ><v-row justify="center">
              <v-icon
                dense
                size="50px"
                color="white"
                style="transform: rotate(90deg)"
              >
                flight
              </v-icon>
            </v-row>
            <v-row class="mt-8" justify="center">
              <span
                style="font-size: 1.8em"
                class="primary--text my-application2"
                >{{ this.$t("home.a3.2.name") }}
              </span>
            </v-row>
            <v-row class="mt-8" justify="center">
              <p
                style="font-size: 1.5em"
                class="white--text my-application text-center"
              >
                {{ this.$t("home.a3.2.details") }}
              </p>
            </v-row>
          </v-col>

          <v-col
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-duration="1000"
            cols="12"
            md="3"
            ><v-row justify="center">
              <v-icon dense size="50px" color="white">mdi-face-agent</v-icon>
            </v-row>
            <v-row class="mt-8" justify="center">
              <span
                style="font-size: 1.8em"
                class="primary--text my-application2"
                >{{ this.$t("home.a3.3.name") }}
              </span>
            </v-row>
            <v-row class="mt-8" justify="center">
              <p
                style="font-size: 1.5em"
                class="white--text my-application text-center"
              >
                {{ this.$t("home.a3.3.details") }}
              </p>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>
<script>
import ItemCard from "@/components/ItemCard";
import ItemCard2 from "@/components/ItemCard2";
import SearchCarsVue from "@/components/Search-Cars.vue";
import Supcriptions from "@/components/My-Supcriptions.vue";
import { GET_VEHICLES_FIRST, GET_SUPCRIPTIONS } from "../gql/queries";
const json = require("../data/vehciles.json");
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: {
    ItemCard,
    SearchCarsVue,
    Supcriptions,
    ItemCard2,
  },
  data() {
    return {
      loading: false,
      vehicles: [],
      vehiclesBrandes: [],
      vehicleModels: [],
      paginado: json,
      supcriptions: [],
      c: "",
      items: [
        {
          marca: "Hyundai Tucson",
          src: require("../assets/fondo/audi.jpg"),
        },
        {
          marca: "Hyundai Tucson",
          src: require("../assets/fondo/mercedes-min.jpg"),
        },
        {
          marca: "Hyundai Tucson",
          src: "https://images.unsplash.com/photo-1571753823631-6b68326c7c19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
        },
        {
          marca: "Highlander",
          src: "https://images.unsplash.com/photo-1511994477422-b69e44bd4ea9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1079&q=80",
        },
      ],
    };
  },

  created() {
    this.getData();
  },
  computed: {
    articles() {
      var articles = [
        {
          img: require("@/assets/customer-support.png"),
          title: this.$t("home.a1.support"),
          text: this.$t("home.a1.support"),
          icon: "mdi-face-agent",
        },

        {
          img: require("@/assets/booking.png"),
          title: this.$t("home.a1.reservation"),
          text: localStorage.getItem("email"),
          icon: "mdi-calendar-month-outline",
        },
        {
          img: require("@/assets/location.png"),
          title: this.$t("home.a1.locations"),
          text: this.$t("home.a1.locations"),
          icon: "mdi-airplane-marker",
        },
      ];

      return articles;
    },
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("locale", locale);
    },
    go() {
      this.$router.push({ name: "Vehicles" });
    },
    async getData() {
      this.loading = false;
      this.phone = localStorage.getItem("phone");
      this.vehiclesBrandes = JSON.parse(localStorage.getItem("VEHICLE_BRANDS"));
      this.vehicleModels = JSON.parse(localStorage.getItem("VEHICLE_MODELS"));
      // var data2 = await this.$apollo.query({
      //   query: GET_SUPCRIPTIONS,
      //   variables: { companyId: this.$companie_id },
      // });

      // this.supcriptions = data2.data.subscriptions.nodes;
      var data = await this.$apollo.query({
        query: GET_VEHICLES_FIRST,
        variables: { companyId: this.$companie_id, branchIds: this.$branch_id },
        fetchPolicy: "no-cache",
      });

      var datos = data.data.vehicles.nodes;
  
      var veh = [];
      for (var a = 0; a < datos.length; a++) {
        veh.push(datos[a]);

        // for (var items of datos[a].vehicles.nodes) {
        //   this.vehicles.push({
        //     rowId: items.rowId,
        //     isDeluxe: items.isDeluxe,
        //     basePrice: items.basePrice,

        //     tractionControlType: items.tractionControlType,
        //     vehicleAmenities: items.vehicleAmenities,
        //     vehicleColor: items.vehicleColor,
        //     vehicleNotAvailableDates: items.vehicleNotAvailableDates,
        //     vehicleSeating: items.vehicleSeating,
        //     branch: items.branch,
        //     currency: items.currency,
        //     vehicleImages: items.vehicleImages,
        //     vehicleBrand: items.vehicleBrand,
        //     vehicleModel: items.vehicleModel,
        //     year: items.year,
        //   });
        // }
      }
      var i = 0;
      var index = 0;
      for (var item of veh) {
        if (i < 8) {
          index = Math.floor(Math.random() * veh.length);

          for (var a = 0; a <= veh.length; a++) {
            if (index == a) {
              this.vehicles.push(veh[a]);
            }
          }
        }
        i++;
      }
      this.loading = true;
    },
    async searchVehicles() {},
  },
};
</script>

<style scoped>
.center {
  display: flex;
  flex-direction: column;
  /* now centers things in X,Y axis */
  align-items: center;

  justify-content: center;
}

/* If the text has multiple lines, add the following: */
.center p {
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
}
.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
</style>

<style>
section {
  position: relative;
}
</style>
