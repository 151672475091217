<template>
  <v-flex class="mt-n1">
    <v-row>
      <v-img
        style="filter: brightness(50%)"
        width="100%"
        max-height="300px"
        src="../assets/fondo/mercedes-min.jpg"
      >
      </v-img>
    </v-row>
    <v-row justify="center" align="center">
      <v-layout justify-center style="margin-top: -300px; position: relative">
        <span
          data-aos="fade-left"
          data-aos-duration="1500"
          style="font-size: 3em"
          class="my-application4 white--text"
          >{{ $t("menuAppBar.nosotros") }}</span
        >
      </v-layout>
    </v-row>
    <v-row style="background-color: #f5f5f5">
      <v-col cols="12" md="10" class="mt-14">
        <v-row justify="center">
          <v-col
            data-aos="fade-right"
            data-aos-duration="800"
            data-aos-delay="400"
            cols="12"
            md="6"
            xs="6"
            sm="6"
          >
            <v-img aspect-ratio="1" src="../assets/141.jpg"> </v-img>
          </v-col>

          <v-col
            data-aos="fade-left"
            data-aos-delay="400"
            data-aos-duration="800"
            cols="12"
            md="6"
            xs="6"
            sm="6"
            ><v-row justify="center">
              <v-col cols="10">
                <v-row justify="center" class="mt-10">
                  <p style="font-size: 2em" class="black--text my-application2">
                    {{ $t("nosotros.1.1") }}
                  </p>
                </v-row>
                <v-row justify="center" class="mt-8">
                  <p
                    style="font-size: 1.5em"
                    class="text-capitalize black--text"
                  >
                    {{ $t("nosotros.1.2") }}
                  </p>
                </v-row>

                <v-row justify="center" class="mt-8">
                  <p
                    style="font-size: 1.5em"
                    class="text-capitalize black--text"
                  >
                    {{ $t("nosotros.1.2") }}
                  </p>
                </v-row>
              </v-col></v-row
            >
          </v-col>
        </v-row>
      </v-col></v-row
    >

    <v-row justify="center">
      <v-col cols="12" md="10" class="mt-14 mb-14">
        <v-row justify="center">
          <v-col cols="6">
            <v-img aspect-ratio="1" src="../assets/mision.jpg"> </v-img>
          </v-col>
          <v-col cols="6">
            <v-row justify="center">
              <v-col cols="12">
                <v-row justify="center">
                  <v-col cols="8">
                    <v-row justify="center">
                      <p
                        style="font-size: 1.5em"
                        class="black--text my-application2 text-center"
                      >
                        {{ $t("nosotros.2.1") }}
                      </p>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="8">
                    <v-row justify="center">
                      <p style="font-size: 1.3em" class="text-center black--text">
                        {{ $t("nosotros.2.2") }}
                      </p>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <!-- <v-col
            data-aos="fade-left"
            data-aos-delay="400"
            data-aos-duration="800"
            cols="12"
            md="6"
            xs="6"
            sm="6"
          >
            <v-row justify="center">
              <v-col cols="10">
                <v-row justify="center" class="mt-10">
                  <p
                    style="font-size: 2em"
                    class="black--text my-application2 text-center"
                  >
                    {{ $t("nosotros.2.1") }}
                  </p>
                </v-row>
                <v-row class="mt-2">
                  <p
                    style="font-size: 1.5em"
                    class="text-capitalize black--text"
                  >
                    {{ $t("nosotros.2.2") }}
                  </p>
                </v-row>
              </v-col>
            </v-row> 
            
            </v-col
          >
          <v-col
            data-aos="fade-right"
            data-aos-duration="800"
            data-aos-delay="400"
            cols="12"
            md="6"
            xs="6"
            sm="6"
          >
            <v-img aspect-ratio="1" src="../assets/historia.jpg"> </v-img>
          </v-col> -->
        </v-row>
      </v-col></v-row
    >

    <v-row style="background-color: #f5f5f5">
      <v-col cols="12" md="10" class="mt-14">
        <v-row dense no-gutters justify="center">
          <v-col
            data-aos="fade-right"
            data-aos-duration="800"
            data-aos-delay="400"
            cols="12"
            md="6"
            xs="6"
            sm="6"
          >
            <v-row dense no-gutters justify="center">
              <v-col cols="10">
                <v-img contain aspect-ratio="1" src="../assets/mision.jpg">
                </v-img>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            data-aos="fade-left"
            data-aos-delay="400"
            data-aos-duration="800"
            cols="12"
            md="6"
            xs="6"
            sm="6"
          >
            <div
              style="al
                justify-content: center;

                align-items: center;
                text-align: center;
                flex-direction: row;
              "
            >
              <v-col cols="12">
                <p style="font-size: 2em" class="black--text my-application2">
                  {{ $t("nosotros.3.1") }}
                </p>
                <p style="font-size: 1.5em" class="text-capitalize black--text">
                  {{ $t("nosotros.3.2") }}
                </p>
                <p style="font-size: 1.5em" class="text-capitalize black--text">
                  {{ $t("nosotros.3.3") }}
                </p>
              </v-col>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-8">
      <p
                        style="font-size: 1.5em"
                        class="black--text my-application2 text-center"
                      >
                        {{ $t("menuAppBar.contactos") }}
                      </p>
                </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <v-row justify="center">
        <p class="text-h6">
        República Dominicana, Santiago.    <br>
        Av. Victor Manuel Espailliat #8 próximo al aeropuerto internacional Cibao.    <br>
          <br>
        Tel: 809-512-5508    <br>
        <br>
        Estados Unidos, New York    <br>
        128-16 161st Jamaica NY 11434    <br>
        <br>
        Tel: 908-548-5146
      </p>
    </v-row>
      </v-col>
  
    </v-row>
  </v-flex>
</template>

<script>
export default {};
</script>

<style></style>
